import React, {useState} from 'react';
import {FileUpload} from "primereact/fileupload";
import {Image} from "primereact/image";
import {Button} from "primereact/button";
import Notiflix from "notiflix";
import api from "../services/api";

const EnviarImagem = ({imagem, setImagem, urlUpload, aviso, infoModelo = false}) => {

    const [totalSize, setTotalSize] = useState();

    const aoUpload = async (e) => {
        try {
            Notiflix.Loading.standard('Fazendo upload da imagem...');

            let formulario = new FormData;
            formulario.append('imagem', e.files[0]);

            await new api().requisitar('post', urlUpload, formulario).then((resposta) => {
                setImagem(resposta[0]);
                Notiflix.Loading.remove();
                aviso.current.show({
                    severity: 'success', summary: 'Confirmação', detail: "Imagem enviada com sucesso!", life: 3000
                });
            }).catch((erro) => {
                Notiflix.Loading.remove();
                throw {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
            });
        } catch (erro) {
            aviso.current.show({
                severity: erro.tipo ?? 'error',
                summary: erro.titulo ?? 'Erro',
                detail: erro.mensagem ?? erro,
                life: 3000
            });
        } finally {
            Notiflix.Loading.remove();
        }
    }

    const imagemAws = () => {
        return (<div className="flex align-items-center justify-content-center flex-wrap">
                {(imagem) ? (<div className="flex align-items-center">
                    <Image alt={imagem} role="presentation" src={imagem} height={80} preview/>
                </div>) : (<div className="flex align-items-center flex-column">
                        <i className="pi pi-image mt-3 p-2" style={{
                            'fontSize': '3em',
                            borderRadius: '50%',
                            backgroundColor: 'var(--surface-b)',
                            color: 'var(--surface-d)'
                        }}></i>
                        <span style={{'fontSize': '1.0em', color: 'var(--text-color-secondary)'}} className="my-2">Arraste a imagem para cá</span>
                    </div>)}
            </div>)
    }

    const headerTemplate = (options) => {
        const {className, chooseButton} = options;

        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                {infoModelo}
                <Button type="button" icon="pi pi-times"
                        className="p-button-outlined p-button-rounded p-button-danger ml-auto"
                        onClick={() => onTemplateRemove()}/>
            </div>);
    }

    const onTemplateRemove = () => {
        setImagem('');
    }

    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;

        setTotalSize(_totalSize);
    }

    return (<FileUpload accept="image/*"
                        maxFileSize={2000000}
                        customUpload
                        headerTemplate={headerTemplate}
                        uploadHandler={aoUpload}
                        itemTemplate={imagemAws}
                        emptyTemplate={imagemAws}
                        onSelect={onTemplateSelect}
                        chooseOptions={{
                            icon: 'pi pi-fw pi-images',
                            iconOnly: true,
                            className: 'custom-choose-btn p-button-rounded p-button-outlined'
                        }}
                        cancelOptions={{
                            icon: 'pi pi-fw pi-times',
                            iconOnly: true,
                            className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'
                        }}
                        auto/>)
}
export default EnviarImagem;

