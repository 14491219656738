const TplStatus = (item) => {
    return (
        <>
            {item.status > 0 && <span className="badge badge-success">Ativo</span>}
            {item.status < 0 && <span className="badge badge-success">Inativo</span>}
        </>
    );
};

export default TplStatus;
