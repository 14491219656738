import React, {useEffect, useRef, useState, useContext} from "react";
import {Toast}                              from "primereact/toast";
import {InputText}                          from "primereact/inputtext";
import {Button}                             from "primereact/button";
import {confirmDialog}                      from "primereact/confirmdialog";
import {Dialog}                             from "primereact/dialog";
import {Dropdown}                           from "primereact/dropdown";
import {PrimeIcons}                         from "primereact/api";

import api                from "../../services/api";
import {Obrigatorio}      from "../../styles/global";
import Tabela             from "../../components/tabela";
import {parametrosPadrao} from "../../configs/constantes";
import Context            from "../../services/context";
import debug              from "../../utils/debug";

const Menu = () => {
	const classe = 'Menu';
	const tabela = 'menus';
	const endpointAPI = 'configuracao/menu';
	const inicial = {id: 0, nome: '', titulo: '', icone: '', identificador: '', idmenu: 0, idtela: 0, idmodulo: 0,ordem:0};
	const aviso = useRef(null);
	const [item, setItem] = useState(inicial);
	const [tituloTela, setTituloTela] = useState('');
	const [exibeTela, setExibirTela] = useState(false);
	const [listar, setListar] = useState(false);
	const [atualizarMenu, setAtualizarMenu] = useContext(Context);
	const [listaPrincipais, setListaPrincipais] = useState(null);
	const [listaModulos, setListaModulos] = useState(null);
	const [listaTelas, setListaTelas] = useState(null);
	const [listaIcones, setListaIcones] = useState(null);
	const [permissoesTela, setPermissoesTela] = useState(null);
	const [parametrosTela, setParametrosTela] = useState(parametrosPadrao);
	
	useEffect((e) => {
		new api().requisitar('get', `configuracao/tela/menu/permissoes`).then((resposta) => {
			setPermissoesTela(resposta);
			const parametros = {
				pesquisarTabela:   true,
				nomeClasse:        classe,
				tabela:            tabela,
				caminhoBreadcrumb: [{label: 'Configurações'}, {label: 'Menus'}],
				endpointApi:       endpointAPI,
				tabelaDados:       {
					reordenar:               true,
					multiplaSelecao:         true,
					botaoAdicionar:          resposta.cadastrar ?? false,
					botaoExcluir:            resposta.excluir ?? false,
					botaoEditarRegistro:     resposta.alterar ?? false,
					botaoExcluirRegistro:    resposta.excluir ?? false,
					botaoAuditoriaRegistro:  resposta.auditoria ?? false,
					botaoVisualizarRegistro: resposta.visualizar ?? false,
					colunas:                 [{legenda: 'Título', campo: 'titulo', ordenavel: true}, {legenda: 'Tela', campo: 'tela', ordenavel: true}]
				},
				abrirFormulario:   aoAbrirFormulario,
				finalizarPesquisa: aoListar
			}
			setParametrosTela(parametros);
		}).catch((erro) => {
			throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
		});
		
		new api().requisitar('get', `${endpointAPI}/principal`).then((resposta) => {
			setListaPrincipais(resposta ?? []);
		}).catch((erro) => {
			debug(erro);
		});
		
		new api().requisitar('get', `/configuracao/modulo/combo`).then((resposta) => {
			setListaModulos(resposta ?? []);
		}).catch((erro) => {
			debug(erro);
		});
		
		let temp = [];
		for (let icone in PrimeIcons) {
			temp.push({id: PrimeIcons[icone], valor: PrimeIcons[icone],})
		}
		setListaIcones(temp);
		
	}, []);
	
	const carregarTelas = async (id)=> {
		await new api().requisitar('get', `/configuracao/tela/combo/${id}`).then((resposta) => {
			setListaTelas(resposta ?? []);
		}).catch((erro) => {
			debug(erro);
		});
	}
	
	const aoSelecionarModulo = async (e) => {
		aoDigitar(e, 'idmodulo');
		carregarTelas(e.target.value);
	}
	
	const aoAbrirFormulario = async (registro) => {
		if (registro.id) {
			new api().requisitar('get', `${endpointAPI}/${registro.id}`).then(async (resposta) => {
				setItem(resposta);
				if (resposta.idmodulo) {
					await carregarTelas(resposta.idmodulo);
				}
				setTituloTela(`Alterar ${classe}`);
			}).catch((erro) => {
				debug(erro);
			});
		} else {
			setItem(inicial);
			setTituloTela(`Cadastro de ${classe}`);
		}
		setExibirTela(true);
	};
	
	const aoListar = () => setListar(false);
	
	const limparTela = () => {
		setItem(inicial);
		setExibirTela(false);
		setListar(true);
	};
	
	const excluir = async (id) => {
		await new api().requisitar('DELETE', `${endpointAPI}/${id}`).then((resposta) => {
			aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
			setAtualizarMenu(true);
			limparTela();
		}).catch((erro) => {
			debug(erro);
			aviso.current.show({severity: 'error', summary: 'Erro', detail: (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado', life: 3000});
		});
	};
	
	const aoExcluirItem = (id) => {
		confirmDialog({
			              message:     `Confirma exclusão do ${classe}?`,
			              header:      'Atenção',
			              icon:        'pi pi-exclamation-triangle',
			              acceptLabel: 'Sim',
			              rejectLabel: 'Não',
			              accept:      () => {
				              excluir(id);
			              },
		              });
	};
	
	const aoSalvar = async () => {
		try {
			if (!item.identificador) {
				throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o Identificador!'};
			}
			
			if (!item.titulo) {
				throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o título!'};
			}
			
			await new api().requisitar((item.id > 0) ? 'put' : 'post', (item.id > 0 ? `${endpointAPI}/${item.id ?? ''}` : endpointAPI), item).then((resposta) => {
				aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
				setAtualizarMenu(true);
				limparTela();
			}).catch((erro) => {
				throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
			});
			
		} catch (erro) {
			aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
		}
	};
	
	const aoDigitar = (e, propriedade) => {
		const texto = (e.target && e.target.value) || '';
		let _item = {...item};
		_item[`${propriedade}`] = texto;
		setItem(_item);
	};
	
	const aoEnter = (e) => {
		if (e.key === 'Enter') {
			aoSalvar(e);
		}
	};
	
	const iconeTpl = (icone) => <div className="d-flex flex-row"><i className={`${icone.valor} mr-2`}></i>{icone.valor}</div>;
	
	const iconeSelTpl = (icone, props) => {
		if (icone) {
			return (<div><i className={`${icone.valor} mr-2`}></i>{icone.valor}</div>);
		}
		
		return (<div>&nbsp;</div>);
	}
	
	const botoesAcaoTela = () => {
		return (<React.Fragment>
			<Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={(e) => setExibirTela(false)}/>
			{item.id > 0 && permissoesTela.excluir && <Button label="Excluir" icon="pi pi-trash" className="p-button-text" onClick={(e) => aoExcluirItem(item.id)}/>}
			{((item.id > 0 && permissoesTela.alterar) || permissoesTela.cadastrar) && <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={aoSalvar}/>}
		</React.Fragment>);
	};
	
	return (
		<Tabela parametros={parametrosTela} listar={listar}>
			<Toast ref={aviso}></Toast>
			<Dialog className='fluid' header={tituloTela} style={{maxWidth: '450px'}} footer={botoesAcaoTela} modal={true} visible={exibeTela} onHide={(e) => setExibirTela(false)}>
				<div className="grid formgrid">
					<div className="field col-12 mt-3">
						<label htmlFor="idmodulo">Menu principal </label><br/>
						<Dropdown className="w-full" autoComplete="none" id="idmenu" value={item.idmenu} options={listaPrincipais} onChange={(e) => aoDigitar(e, 'idmenu')} optionLabel="valor" optionValue="id"/>
					</div>
					<div className="field col-12 mt-3">
						<label htmlFor="titulo">Título<Obrigatorio>*</Obrigatorio> </label><br/>
						<InputText className="w-full" autoComplete="none" id="nome" maxLength={100} value={item.titulo} onChange={(e) => aoDigitar(e, 'titulo')} onKeyDown={aoEnter} required/>
					</div>
					<div className="field col-12 mt-3">
						<label htmlFor="nome">Identificador<Obrigatorio>*</Obrigatorio> </label><br/>
						<InputText className="w-full" autoComplete="none" id="nome" maxLength={50} value={item.identificador} onChange={(e) => aoDigitar(e, 'identificador')} onKeyDown={aoEnter} required/>
					</div>
					<div className="field col-12 mt-3">
						<label htmlFor="icone">Ícone</label>
						<Dropdown className="w-full" autoComplete="none" showClear id="icone" value={item.icone} options={listaIcones} filter onChange={(e) => aoDigitar(e, 'icone')} optionLabel="valor"
						          optionValue="id" itemTemplate={iconeTpl} valueTemplate={iconeSelTpl} required/>
					</div>
					<div className="field col-12 mt-3">
						<label htmlFor="idmodulo">Módulo </label><br/>
						<Dropdown className="w-full" autoComplete="none" id="idmodulo" value={item.idmodulo} options={listaModulos} onChange={aoSelecionarModulo} optionLabel="valor" optionValue="id"/>
					</div>
					<div className="field col-12 mt-3">
						<label htmlFor="idmodulo">Tela </label><br/>
						<Dropdown className="w-full" autoComplete="none" id="idtela" value={item.idtela} options={listaTelas} onChange={(e) => aoDigitar(e, 'idtela')} optionLabel="valor" optionValue="id"/>
					</div>
				</div>
			</Dialog>
		</Tabela>
	);
}


export default Menu;
