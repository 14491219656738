import React, {useEffect, useRef, useState} from "react";
import {Toast}                              from "primereact/toast";
import {InputText}                          from "primereact/inputtext";
import {Button}                             from "primereact/button";
import {confirmDialog}                      from "primereact/confirmdialog";
import {Dialog}                             from "primereact/dialog";
import {Tree}                               from "primereact/tree";

import api                from "../../services/api";
import {Obrigatorio}      from "../../styles/global";
import Tabela             from "../../components/tabela";
import {parametrosPadrao}  from "../../configs/constantes";
import {TabPanel, TabView} from "primereact/tabview";
import debug from "../../utils/debug";
import TplStatus from "../../components/tplStatus";


const Perfil = () => {
	const classe = 'Perfil';
	const tabela = 'perfis';
	const endpointAPI = 'seguranca/perfil';
	const inicial = {id: 0, nome: '', permissoes: []};
	const aviso = useRef(null);
	const [item, setItem] = useState(inicial);
	const [tituloTela, setTituloTela] = useState('');
	const [exibeTela, setExibirTela] = useState(false);
	const [listar, setListar] = useState(false);
	const [permissoes, setPermissoes] = useState(null);
	const [arvore, setArvore] = useState(null);
	const [permissoesTela, setPermissoesTela] = useState(null);
	const [parametrosTela, setParametrosTela] = useState(parametrosPadrao);

	const aoAbrirFormulario = async (registro) => {
		if (registro.id) {
			await new api().requisitar('get', `${endpointAPI}/${registro.id}`).then((resposta) => {
				setItem(resposta);
				setTituloTela(`Alterar ${classe}`);
			}).catch((erro) => {
				debug(erro);
				aviso.current.show({severity: 'error', summary: 'Erro', detail: (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado', life: 3000});
			});
		} else {
			setItem(inicial);
			setTituloTela(`Cadastro de ${classe}`);
		}
		setExibirTela(true);
	};

	const aoListar = () => setListar(false);

	const limparTela = () => {
		setItem(inicial);
		setExibirTela(false);
		setListar(true);
	};
	
	const reativar = async (id) => {
		await new api().requisitar('PUT', `${endpointAPI}/reativar/${id}`).then((resposta) => {
			aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
			limparTela();
		}).catch((erro) => {
			debug(erro);
			aviso.current.show({severity: 'error', summary: 'Erro', detail: (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado', life: 3000});
		});
	};
	
	const aoReativarItem = (id) => {
		confirmDialog({
			              message:     `Confirma reativação do ${classe}?`,
			              header:      'Atenção',
			              icon:        'pi pi-exclamation-triangle',
			              acceptLabel: 'Sim',
			              rejectLabel: 'Não',
			              accept:      () => {
				              reativar(id);
			              },
		              });
	};

	const excluir = async (id) => {
		await new api().requisitar('DELETE', `${endpointAPI}/${id}`).then((resposta) => {
			aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
			limparTela();
		}).catch((erro) => {
			debug(erro);
			aviso.current.show({severity: 'error', summary: 'Erro', detail: (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado', life: 3000});
		});
	};

	const aoExcluirItem = (id) => {
		confirmDialog({
			              message:     `Confirma exclusão do ${classe}?`,
			              header:      'Atenção',
			              icon:        'pi pi-exclamation-triangle',
			              acceptLabel: 'Sim',
			              rejectLabel: 'Não',
			              accept:      () => {
				              excluir(id);
			              },
		              });
	};

	const aoSalvar = async () => {
		try {
			if (!item.nome) {
				throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o nome!'};
			}

			await new api().requisitar((item.id > 0) ? 'put' : 'post', (item.id > 0 ? `${endpointAPI}/${item.id ?? ''}` : endpointAPI), item).then((resposta) => {
				aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
				limparTela();
			}).catch((erro) => {
				throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
			});

		} catch (erro) {
			aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
		}
	};

	const aoDigitar = (e, propriedade) => {
		let _item = {...item};
		if (propriedade == 'permissoes') {
			_item[`${propriedade}`] = e.value;
		} else {
			const texto = (e.target && e.target.value) || '';
			_item[`${propriedade}`] = texto;
		}
		setItem(_item);
	};

	const aoEnter = (e) => {
		if (e.key === 'Enter') {
			aoSalvar(e);
		}
	};

	useEffect((e) => {
		new api().requisitar('get', `configuracao/tela/perfil/permissoes`).then((resposta) => {
			setPermissoesTela(resposta);
			const parametros = {
				pesquisarTabela:   true,
				nomeClasse:        classe,
				tabela:            tabela,
				caminhoBreadcrumb: [{label: 'Segurança'}, {label: 'Perfis'}],
				endpointApi:       endpointAPI,
				desativar:         true,
				tabelaDados:       {
					reordenar:               false,
					multiplaSelecao:         true,
					botaoAdicionar:          resposta.cadastrar ?? false,
					botaoExcluir:            resposta.excluir ?? false,
					botaoEditarRegistro:     resposta.alterar ?? false,
					botaoExcluirRegistro:    resposta.excluir ?? false,
					botaoAuditoriaRegistro:  resposta.auditoria ?? false,
					botaoVisualizarRegistro: resposta.visualizar ?? false,
					colunas:                 [
						{legenda: 'Nome do perfil', campo: 'nome', ordenavel: true},
						{legenda: 'Status', campo: 'status', ordenavel: true, template: TplStatus}
					]
				},
				abrirFormulario:   aoAbrirFormulario,
				finalizarPesquisa: aoListar
			}
			setParametrosTela(parametros);
		}).catch((erro) => {
			throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
		});

		new api().requisitar('get', `/configuracao/modulo/arvore`).then((resposta) => {
			setArvore(resposta ?? []);
		}).catch((erro) => {
			debug(erro);
		});

	}, []);

	const botoesAcaoTela = () => {
		return (<React.Fragment>
			<Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={(e) => setExibirTela(false)}/>
			{item.id > 0 && item.status>0  && permissoesTela.excluir && <Button label="Desativar" icon="pi pi-trash" className="p-button-text" onClick={(e) => aoExcluirItem(item.id)}/>}
			{item.id > 0 && item.status<0  && permissoesTela.reativar && <Button label="Reativar" icon="pi pi-undo" className="p-button-text" onClick={(e) => aoReativarItem(item.id)}/>}
			{((item.id > 0 && permissoesTela.alterar) || permissoesTela.cadastrar) && <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={aoSalvar}/>}
		</React.Fragment>);
	};

	return (
		<Tabela parametros={parametrosTela} listar={listar}>
			<Toast ref={aviso}></Toast>
			<Dialog className='fluid' header={tituloTela} style={{maxWidth: '99%'}} footer={botoesAcaoTela} modal={true} visible={exibeTela} onHide={(e) => setExibirTela(false)}>
				<div className="grid formgrid">
					<div className="field col-12">
						<label htmlFor="nome">Nome<Obrigatorio>*</Obrigatorio> </label><br/>
						<InputText className="w-full" autoComplete="none" id="nome" maxLength={50} value={item.nome} onChange={(e) => aoDigitar(e, 'nome')} onKeyDown={aoEnter} required/>
					</div>
					<div className="field col-12">
						<label>Permissões</label><br/>
						<Tree disabled={item.id == 1} filter={true} value={arvore} selectionMode="checkbox" selectionKeys={item.permissoes} onSelectionChange={(e) => aoDigitar(e, 'permissoes')}/>
					</div>
				</div>
			</Dialog>
		</Tabela>
	);
}


export default Perfil;
