import React, {useEffect, useRef, useState} from "react";
import {Toast}                              from "primereact/toast";
import {InputText}                          from "primereact/inputtext";
import {Button}                             from "primereact/button";
import {confirmDialog}                      from "primereact/confirmdialog";
import {Dialog}                             from "primereact/dialog";
import {Dropdown}                           from "primereact/dropdown";
import {Editor}                             from "primereact/editor";
import {Column}                             from "primereact/column";
import {Panel}                              from "primereact/panel";
import {DataTable}                          from "primereact/datatable";

import api                from "../../services/api";
import {Obrigatorio}      from "../../styles/global";
import Tabela             from "../../components/tabela";
import {parametrosPadrao} from "../../configs/constantes";
import {SelectButton}     from "primereact/selectbutton";
import {FileUpload}       from "primereact/fileupload";
import debug              from "../../utils/debug";
import TplStatus          from "../../components/tplStatus";


const Post = () => {
    const classe = 'Post';
    const tabela = 'posts';
    const endpointAPI = 'cadastro/postagem';
    const aviso = useRef(null);
    const inicialPost = {tipo: 0, url: '', id: 0};
    const inicial = {id: 0, titulo: '', idcategoria: 0, categoria: '', idsubcategoria: 0, subcategoria: '', texto: '', itens: [inicialPost]};
    const [itemPost, setItemPost] = useState(inicialPost);
    const [item, setItem] = useState(inicial);
    const [html, setHtml] = useState('');
    const [tituloTela, setTituloTela] = useState('');
    const [exibeTela, setExibirTela] = useState(false);
    const [exibeTelaItem, setExibirTelaItem] = useState(false);
    const [listar, setListar] = useState(false);
    const [listaCategoria, setListaCategoria] = useState();
    const [listaSubCategoria, setListaSubCategoria] = useState();
    const [permissoesTela, setPermissoesTela] = useState(null);
    const [parametrosTela, setParametrosTela] = useState(parametrosPadrao);

    useEffect((e) => {
        new api().requisitar('get', `configuracao/tela/post/permissoes`).then((resposta) => {
            setPermissoesTela(resposta);
            const parametros = {
                pesquisarTabela  : true,
                nomeClasse       : classe,
                tabela           : tabela,
                caminhoBreadcrumb: [{label: 'Cadastro'}, {label: 'Posts'}],
                endpointApi      : endpointAPI,
                excluirRegistro  : true,
                tabelaDados      : {
                    reordenar              : false,
                    multiplaSelecao        : true,
                    botaoAdicionar         : resposta.cadastrar ?? false,
                    botaoExcluir           : resposta.excluir ?? false,
                    botaoEditarRegistro    : resposta.alterar ?? false,
                    botaoExcluirRegistro   : resposta.desabilitar ?? false,
                    botaoAuditoriaRegistro : resposta.auditoria ?? false,
                    botaoVisualizarRegistro: resposta.visualizar ?? false,
                    filtroStatus           : true, // HABILITA FILTRO DE STATUS
                    colunas                : [
                        {legenda: 'Categoria', campo: 'categoria', ordenavel: true}, 
                        {legenda: 'Sub-Categoria', campo: 'subcategoria', ordenavel: true}, 
                        {legenda: 'Título', campo: 'titulo', ordenavel: true},
                        {legenda: 'Status', campo: 'status', ordenavel: true, template: TplStatus}
                    ]
                },
                abrirFormulario  : aoAbrirFormulario,
                finalizarPesquisa: aoListar
            }
            setParametrosTela(parametros);
        }).catch((erro) => {
            throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
        });

        new api().requisitar('get', 'cadastro/categoria/combo').then((resposta) => {
            setListaCategoria(resposta);
        }).catch((erro) => {
            debug(erro);
        })
    }, []);

    const aoAbrirFormulario = (registro) => {
        if (registro.id) {
            new api().requisitar('get', `${endpointAPI}/${registro.id}`).then((resposta) => {
                carregarSubcategoria(resposta.idcategoria);
                setItem(resposta);
                setHtml(resposta.texto);
            }).catch((erro) => {
                debug(erro);
            })
            setTituloTela(`Alterar ${classe}`);
        } else {
            setItem(inicial);
            setHtml('');
            setTituloTela(`Cadastro de ${classe}`);
        }
        setExibirTela(true);
    };

    const aoListar = () => setListar(false);

    const carregarSubcategoria = (categoria) => {
        new api().requisitar('get', `cadastro/subcategoria/combo/${categoria}`).then((resposta) => {
            setListaSubCategoria(resposta);
        }).catch((erro) => {
            debug(erro);
        })
    }

    const limparTela = () => {
        setHtml('');
        setItem(inicial);
        setExibirTela(false);
        setListar(true);
    };

    const reativar = async (id) => {
        await new api().requisitar('PUT', `${endpointAPI}/reativar/${id}`).then((resposta) => {
            aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
            limparTela();
        }).catch((erro) => {
            debug(erro);
            aviso.current.show({severity: 'error', summary: 'Erro', detail: (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado', life: 3000});
        });
    };

    const aoReativarItem = (id) => {
        confirmDialog({
                          message    : `Confirma reativação do ${classe}?`,
                          header     : 'Atenção',
                          icon       : 'pi pi-exclamation-triangle',
                          acceptLabel: 'Sim',
                          rejectLabel: 'Não',
                          accept     : () => {
                              reativar(id);
                          },
                      });
    };

    const excluir = async (id) => {
        await new api().requisitar('DELETE', `${endpointAPI}/${id}`).then((resposta) => {
            aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
            limparTela();
        }).catch((erro) => {
            debug(erro);
            aviso.current.show({severity: 'error', summary: 'Erro', detail: (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado', life: 3000});
        });
    };

    const aoExcluirItem = (id) => {
        confirmDialog({
                          message    : `Confirmar desativação do ${classe}?`,
                          header     : 'Atenção',
                          icon       : 'pi pi-exclamation-triangle',
                          acceptLabel: 'Sim',
                          rejectLabel: 'Não',
                          accept     : () => {
                              excluir(id);
                          },
                      });
    };

    const aoExcluirItemPost = (id) => {
        confirmDialog({
                          message    : `Confirmar exclusão do Item?`,
                          header     : 'Atenção',
                          icon       : 'pi pi-exclamation-triangle',
                          acceptLabel: 'Sim',
                          rejectLabel: 'Não',
                          accept     : () => {
                              const _itensPost = [];
                              for (let i = 0; i < item.itens.length; i++) {
                                  if (item.itens[i].id != id) {
                                      _itensPost.push(item.itens[i]);
                                  }
                              }
                              setItem({...item, itens: _itensPost});
                          },
                      });
    };

    const aoSalvar = async () => {
        try {
            if (!item.idcategoria) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe a categoria!'};
            }
            if (!item.idsubcategoria) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe a sub-categoria!'};
            }
            if (!item.titulo) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o título!'};
            }
            if (!html) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o texto!'};
            }

            if (!item.itens[0] && !item.itens[1] && !item.itens[2]) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe pelo menos 1 item!'};
            }

            item.texto = html;
            await new api().requisitar((item.id > 0) ? 'put' : 'post', (item.id > 0 ? `${endpointAPI}/${item.id ?? ''}` : endpointAPI), item).then((resposta) => {
                aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
                limparTela();
            }).catch((erro) => {
                throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
            });

        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    };

    const aoDigitar = (e, propriedade) => {
        const texto = (e.target && e.target.value) || '';
        if (propriedade != 'itemPostTipo' && propriedade != 'itemPostUrl') {
            let _item = {...item};
            _item[`${propriedade}`] = texto;
            setItem(_item);
            if (propriedade == 'idcategoria') {
                carregarSubcategoria(texto);
            }
        } else {
            let _itemPost = {...itemPost};
            if (propriedade === 'itemPostTipo') {
                _itemPost.url = '';
                if (texto === 'URL de Imagem') {
                    _itemPost.tipo = 0;
                } else if (texto === 'URL de Vídeo') {
                    _itemPost.tipo = 2;
                } else if (texto === 'Upload de Vídeo') {
                    _itemPost.tipo = 1;
                } else if (texto === 'Upload de Imagem') {
                    _itemPost.tipo = 3;
                }
            } else {
                _itemPost.url = texto;
            }

            setItemPost(_itemPost);
        }
    };

    const aoAdicionarItem = () => {
        setItemPost(inicialPost);
        setExibirTelaItem(true);
    }

    const aoEnter = (e) => {
        if (e.key === 'Enter') {
            aoSalvar(e);
        }
    };

    const aoConfirmarItemPost = () => {
        try {
            if (!itemPost.url) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe a URL do item!'};
            }
            setExibirTelaItem(false);
            let _itens = item.itens ?? [];
            _itens.push({tipo: itemPost.tipo == 3 ? 0 : itemPost.tipo, url: itemPost.url, id: (-1) * item.itens.length});
            setItem({...item, itens: _itens});
            aviso.current.show({severity: 'success', summary: 'Confirmação', detail: "URL adicionada com sucesso!", life: 3000});
        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    }
    const aoUpload = async (e) => {
        try {
            let formulario = new FormData;
            formulario.append('imagem', e.files[0]);

            await new api().requisitar('post', `${endpointAPI}/upload`, formulario)
                           .then((resposta) => {
                               setExibirTelaItem(false);

                               let _itens = item.itens ?? [];
                               _itens.push({tipo: itemPost.tipo == 3 ? 0 : itemPost.tipo, url: resposta, id: (-1) * item.itens.length});
                               setItem({...item, itens: _itens});
                               aviso.current.show({severity: 'success', summary: 'Confirmação', detail: "Arquivo enviado com sucesso!", life: 3000});
                           })
                           .catch((erro) => {
                               throw  {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
                           });
        } catch (erro) {
            aviso.current.show({severity: erro.tipo ?? 'error', summary: erro.titulo ?? 'Erro', detail: erro.mensagem ?? erro, life: 3000});
        }
    }

    const botoesAcaoTela = () => {
        return (<React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={(e) => setExibirTela(false)}/>
            {item.id > 0 && item.status > 0 && permissoesTela.desabilitar && <Button label="Desativar" icon="pi pi-times" className="p-button-text" onClick={(e) => aoExcluirItem(item.id)}/>}
            {item.id > 0 && item.status < 0 && permissoesTela.reativar && <Button label="Reativar" icon="pi pi-undo" className="p-button-text" onClick={(e) => aoReativarItem(item.id)}/>}
            {((item.id > 0 && permissoesTela.alterar) || permissoesTela.cadastrar) && <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={aoSalvar}/>}
        </React.Fragment>);
    };

    const tplTipo = (registro) => {
        return (registro?.tipo === 0 ? 'Imagem' : 'Vídeo');
    };

    const tplBtnExcluirItem = (registro) => {
        return (<Button icon="pi pi-trash" className="p-button-danger p-button-rounded mt-2" onClick={(e) => aoExcluirItemPost(registro?.id)}/>);
    }

    const tplHeaderPanel = (options) => {
        const className = `${options.className} justify-content-start`;
        const titleClassName = `${options.titleClassName} ml-2`;
        const style = {fontSize: '1.25rem'};

        return (
            <div className={className}>
                <span className={titleClassName} style={style}>Itens</span>
                <Button label="Adicionar" icon="pi pi-plus" className="p-button-info ml-5" onClick={aoAdicionarItem}/>
            </div>
        );
    };

    return (
        <Tabela parametros={parametrosTela} listar={listar}>
            <Toast ref={aviso}></Toast>
            <Dialog className='fluid' header={tituloTela} style={{maxWidth: '90%', width: '800px'}} footer={botoesAcaoTela} modal={true} visible={exibeTela} onHide={(e) => setExibirTela(false)}>
                <div className="grid formgrid">
                    <div className="field col-12 mt-3">
                        <label htmlFor="idcategoria">Categoria<Obrigatorio>*</Obrigatorio> </label><br/>
                        <Dropdown className="w-full" autoComplete="none" id="idcategoria" value={item.idcategoria} options={listaCategoria} onChange={(e) => aoDigitar(e, 'idcategoria')} optionLabel="valor"
                                  optionValue="id" required/>
                    </div>
                </div>
                <div className="grid formgrid">
                    <div className="field col-12 mt-3">
                        <label htmlFor="idsubcategoria">Sub-Categoria<Obrigatorio>*</Obrigatorio> </label><br/>
                        <Dropdown className="w-full" autoComplete="none" id="idsubcategoria" value={item.idsubcategoria} options={listaSubCategoria} onChange={(e) => aoDigitar(e, 'idsubcategoria')} optionLabel="valor"
                                  optionValue="id" required/>
                    </div>
                </div>
                <div className="grid formgrid">
                    <div className="field col-12 mt-3">
                        <label htmlFor="titulo">Título<Obrigatorio>*</Obrigatorio> </label><br/>
                        <InputText className="w-full" autoComplete="none" id="nome" maxLength={200} value={item.titulo} onChange={(e) => aoDigitar(e, 'titulo')} required/>
                    </div>
                </div>
                <div className="grid formgrid">
                    <div className="field col-12 mt-3">
                        <label htmlFor="texto">Texto<Obrigatorio>*</Obrigatorio> </label><br/>
                        <Editor className="w-full" id="texto" value={html} onTextChange={(e) => setHtml(e.htmlValue)} style={{height: '320px'}}/>
                    </div>
                </div>
                <Panel headerTemplate={tplHeaderPanel}>
                    <DataTable className="datatable-sm" emptyMessage=" " dataKey='id' value={item.itens}>
                        <Column field='tipo' header="Tipo" body={tplTipo}/>
                        <Column field='url' header="Url"/>
                        <Column body={tplBtnExcluirItem}/>
                    </DataTable>
                </Panel>
            </Dialog>

            <Dialog className={'fluid'} style={{maxWidth: '90%', width: '700px'}} modal={true} visible={exibeTelaItem} header={'Adicionar item ao post'} onHide={(e) => setExibirTelaItem(false)}>
                <div className="grid formgrid">
                    <div className="field col-12 mt-3">
                        <SelectButton value={itemPost.tipo} onChange={(e) => aoDigitar(e, 'itemPostTipo')} options={['URL de Imagem', 'URL de Vídeo', 'Upload de Imagem', 'Upload de Vídeo']}/>
                    </div>
                    {(itemPost.tipo == 0 || itemPost.tipo == 2) &&
                     <div className="field col-12 mt-3">
                         <InputText placeholder={itemPost.tipo == 0 ? 'URL da imagem' : 'URL do vídeo'} className="w-full" autoComplete="none" id="url" maxLength={200} value={itemPost.url} onChange={(e) => aoDigitar(e, 'itemPostUrl')} required/> <br/>
                         <Button label="OK" icon="pi pi-check" className="p-button-success mt-2" onClick={aoConfirmarItemPost}/>
                     </div>
                    }
                    {itemPost.tipo == 3 &&
                     <div className="field col-12 mt-3">
                         <FileUpload id={'upimagem'} mode="basic" name="arquivo[]" accept="image/*" maxFileSize={100000000} uploadHandler={aoUpload} auto customUpload chooseLabel="Enviar imagem"/>
                     </div>
                    }
                    {itemPost.tipo == 1 &&
                     <div className="field col-12 mt-3">
                         <FileUpload id={'upivideo'} mode="basic" name="arquivo[]" accept="video/mp4" maxFileSize={100000000} uploadHandler={aoUpload} auto customUpload chooseLabel="Enviar vídeo"/>
                     </div>
                    }
                </div>
            </Dialog>

        </Tabela>
    );
}

export default Post;
