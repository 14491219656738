import {Button}                             from "primereact/button";
import {confirmDialog}                      from "primereact/confirmdialog";
import {Dialog}                             from "primereact/dialog";
import {InputText}                          from "primereact/inputtext";
import {Toast}                              from "primereact/toast";
import React, {useEffect, useRef, useState} from "react";
import EnviarImagem                         from "../../components/enviarImagem";
import Tabela                               from "../../components/tabela";
import {parametrosPadrao}                   from "../../configs/constantes";

import api           from "../../services/api";
import {Obrigatorio} from "../../styles/global";
import debug         from "../../utils/debug";
import TplStatus     from "../../components/tplStatus";

const Categoria = () => {
    const classe = 'Categoria';
    const tabela = 'categorias';
    const endpointAPI = 'cadastro/categoria';
    const inicial = {id: 0, titulo: '', imagem: ''};
    const aviso = useRef(null);
    const [item, setItem] = useState(inicial);
    const [tituloTela, setTituloTela] = useState('');
    const [exibeTela, setExibirTela] = useState(false);
    const [listar, setListar] = useState(false);
    const [permissoesTela, setPermissoesTela] = useState(null);
    const [parametrosTela, setParametrosTela] = useState(parametrosPadrao);

    useEffect((e) => {
        new api().requisitar('get', `configuracao/tela/categoria/permissoes`).then((resposta) => {
            setPermissoesTela(resposta);
            const parametros = {
                pesquisarTabela  : true,
                artigo           : 'a',
                nomeClasse       : classe,
                tabela           : tabela,
                caminhoBreadcrumb: [{label: 'Cadastro'}, {label: 'Categorias'}],
                endpointApi      : endpointAPI,
                excluirRegistro  : true,
                tabelaDados      : {
                    reordenar              : true,
                    multiplaSelecao        : true,
                    botaoAdicionar         : resposta.cadastrar ?? false,
                    botaoExcluir           : resposta.excluir ?? false,
                    botaoEditarRegistro    : resposta.alterar ?? false,
                    botaoExcluirRegistro   : resposta.desabilitar ?? false,
                    botaoAuditoriaRegistro : resposta.auditoria ?? false,
                    botaoVisualizarRegistro: resposta.visualizar ?? false,
                    filtroStatus           : true, // HABILITA FILTRO DE STATUS
                    colunas                : [
                        {legenda: 'Título', campo: 'titulo', ordenavel: true},
                        {legenda: 'Status', campo: 'status', ordenavel: true, template: TplStatus}
                    ],
                },
                abrirFormulario  : aoAbrirFormulario,
                finalizarPesquisa: aoListar
            }
            setParametrosTela(parametros);
        }).catch((erro) => {
            throw {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
        });

    }, []);

    const aoAbrirFormulario = (registro) => {
        if (registro.id) {
            setItem({...registro});
            setTituloTela(`Alterar ${classe}`);
        } else {
            setItem(inicial);
            setTituloTela(`Cadastro de ${classe}`);
        }
        setExibirTela(true);
    };

    const aoListar = () => setListar(false);

    const limparTela = () => {
        setItem(inicial);
        setExibirTela(false);
        setListar(true);
    };

    const excluir = async (id) => {
        await new api().requisitar('DELETE', `${endpointAPI}/${id}`).then((resposta) => {
            aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
            limparTela();
        }).catch((erro) => {
            debug(erro);
            aviso.current.show({
                                   severity: 'error',
                                   summary : 'Erro',
                                   detail  : (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado',
                                   life    : 3000
                               });
        });
    };

    const reativar = async (id) => {
        await new api().requisitar('PUT', `${endpointAPI}/reativar/${id}`).then((resposta) => {
            aviso.current.show({severity: 'success', summary: 'Confirmação', detail: resposta.mensagem, life: 3000});
            limparTela();
        }).catch((erro) => {
            debug(erro);
            aviso.current.show({
                                   severity: 'error',
                                   summary : 'Erro',
                                   detail  : (erro.response && erro.response.data && erro.response.data.error_description) ? erro.response.data.error_description : 'Ocorreu um erro não tratado',
                                   life    : 3000
                               });
        });
    };

    const aoReativarItem = (id) => {
        confirmDialog({
                          message    : `Confirmar reativação da ${classe}?`,
                          header     : 'Atenção',
                          icon       : 'pi pi-exclamation-triangle',
                          acceptLabel: 'Sim',
                          rejectLabel: 'Não',
                          accept     : () => {
                              reativar(id);
                          },
                      });
    };

    const aoExcluirItem = (id) => {
        confirmDialog({
                          message    : `Confirmar desativação da ${classe}?`,
                          header     : 'Atenção',
                          icon       : 'pi pi-exclamation-triangle',
                          acceptLabel: 'Sim',
                          rejectLabel: 'Não',
                          accept     : () => {
                              excluir(id);
                          },
                      });
    };

    const aoSalvar = async () => {
        try {
            if (!item.titulo) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o título!'};
            }

            await new api().requisitar((item.id > 0) ? 'put' : 'post', (item.id > 0 ? `${endpointAPI}/${item.id ?? ''}` : endpointAPI), item).then((resposta) => {
                aviso.current.show({
                                       severity: 'success',
                                       summary : 'Confirmação',
                                       detail  : resposta.mensagem,
                                       life    : 3000
                                   });
                limparTela();
            }).catch((erro) => {
                throw {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
            });

        } catch (erro) {
            aviso.current.show({
                                   severity: erro.tipo ?? 'error',
                                   summary : erro.titulo ?? 'Erro',
                                   detail  : erro.mensagem ?? erro,
                                   life    : 3000
                               });
        }
    };

    const aoDigitar = (e, propriedade) => {
        const texto = (e.target && e.target.value) || '';
        let _item = {...item};
        _item[`${propriedade}`] = texto;
        setItem(_item);
    };

    const aoEnter = (e) => {
        if (e.key === 'Enter') {
            aoSalvar(e);
        }
    };

    const botoesAcaoTela = () => {
        return (<React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={(e) => setExibirTela(false)}/>
            {item.id > 0 && item.status > 0 && permissoesTela.desabilitar && <Button label="Desativar" icon="pi pi-times" className="p-button-text" onClick={(e) => aoExcluirItem(item.id)}/>}
            {item.id > 0 && item.status < 0 && permissoesTela.reativar && <Button label="Reativar" icon="pi pi-undo" className="p-button-text" onClick={(e) => aoReativarItem(item.id)}/>}
            {((item.id > 0 && permissoesTela.alterar) || permissoesTela.cadastrar) && <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={aoSalvar}/>}
        </React.Fragment>);
    };

    return (
        <Tabela parametros={parametrosTela} listar={listar}>
            <Toast ref={aviso}></Toast>
            <Dialog className='fluid' header={tituloTela} style={{maxWidth: '90%', width: '800px'}}
                    footer={botoesAcaoTela} modal={true} visible={exibeTela} onHide={(e) => setExibirTela(false)}>
                <div className="grid formgrid">
                    <div className="field col-12 mt-3">
                        <label htmlFor="titulo">Título<Obrigatorio>*</Obrigatorio> </label><br/>
                        <InputText className="w-full" autoComplete="none" id="nome" maxLength={200} value={item.titulo}
                                   onChange={(e) => aoDigitar(e, 'titulo')} onKeyDown={aoEnter} required/>
                    </div>
                    <div className="field col-12 mt-3">
                        <label>Imagem da categoria<Obrigatorio>*</Obrigatorio> </label><br/>
                        <EnviarImagem imagem={item.imagem} setImagem={(e) => {
                            setItem({...item, imagem: e})
                        }} urlUpload={endpointAPI + '/upload'} aviso={aviso} pasta={'categorias'}/>
                    </div>
                </div>
            </Dialog>
        </Tabela>
    );
}

export default Categoria;
