import React, {useRef} from "react";
import {Toast}         from "primereact/toast";
import {Container}     from "../styles/global";

const Dashboard = () => {
	const caminhoBreadcrumb = [{label: 'Dashboard'}];
	const iconeHome = {icon: 'pi pi-home'};
	const classe = 'Dashboard';
	const endpointAPI = 'dashboard';
	const aviso = useRef(null);
	
	const parametros = {
		pesquisarTabela:   false,
		caminhoBreadcrumb: [{label: 'Dashboard'}],
		endpointApi:       endpointAPI,
		nomeClasse:        classe,
		tabelaDados:       false,
	}
	
	return (
		<Container>
			<Toast ref={aviso}></Toast>
			<div></div>
		</Container>
	);
}

export default Dashboard;
