import {Button}                             from "primereact/button";
import {confirmDialog}                      from "primereact/confirmdialog";
import {Dialog}                             from "primereact/dialog";
import {InputText}                          from "primereact/inputtext";
import {Toast}                              from "primereact/toast";
import React, {useEffect, useRef, useState} from "react";
import EnviarImagem                         from "../../components/enviarImagem";
import Tabela                               from "../../components/tabela";
import {parametrosPadrao}                   from "../../configs/constantes";

import api           from "../../services/api";
import {Obrigatorio} from "../../styles/global";
import debug         from "../../utils/debug";
import TplStatus     from "../../components/tplStatus";
import {Card}        from "primereact/card";

const Frase = () => {

    const endpointAPI = 'precadastro/cliente';
    const inicial = {id: 0, nome: '', fraseinicial: ''};
    const aviso = useRef(null);
    const [item, setItem] = useState(inicial);
    const [permissoesTela, setPermissoesTela] = useState(null);

    useEffect((e) => {
        new api().requisitar('get', `configuracao/tela/frase/permissoes`).then((resposta) => {
            setPermissoesTela(resposta);
            if (resposta.listar) {
                carregarFraseInicial();
            }
        }).catch((erro) => {
            throw {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
        });

    }, []);

    const carregarFraseInicial = () => {
        new api().requisitar('get', `${endpointAPI}/2`).then((resposta) => {
            setItem(resposta);
        }).catch((erro) => {
            throw {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
        });
    }

    const aoSalvar = async () => {
        try {
            if (!item.fraseinicial) {
                throw {tipo: 'warn', titulo: 'Atenção!!', mensagem: 'Informe o texto da frase!'};
            }

            await new api().requisitar('put', `${endpointAPI}/${item.id}`, item).then((resposta) => {
                aviso.current.show({
                                       severity: 'success',
                                       summary : 'Confirmação',
                                       detail  : 'Frase inicial alterada com sucesso!',
                                       life    : 3000
                                   });
            }).catch((erro) => {
                throw {tipo: 'error', titulo: 'Falha!!', mensagem: erro.response.data ?? 'Ocorreu um erro não tratado'};
            });

        } catch (erro) {
            aviso.current.show({
                                   severity: erro.tipo ?? 'error',
                                   summary : erro.titulo ?? 'Erro',
                                   detail  : erro.mensagem ?? erro,
                                   life    : 3000
                               });
        }
    };

    const aoDigitar = (e, propriedade) => {
        const texto = (e.target && e.target.value) || '';
        let _item = {...item};
        _item[`${propriedade}`] = texto;
        setItem(_item);
    };

    const aoEnter = (e) => {
        if (e.key === 'Enter') {
            aoSalvar(e);
        }
    };

    return (
        <div>
            <Toast ref={aviso}></Toast>

            <Card title="Frase Tela Inicial">
                <div className="grid formgrid">
                    <div className="field col-12 mt-3">
                        <label htmlFor="titulo">Texto da frase<Obrigatorio>*</Obrigatorio> </label><br/>
                        <InputText className="w-full" autoComplete="none" id="fraseinicial" maxLength={200} value={item.fraseinicial}
                                   onChange={(e) => aoDigitar(e, 'fraseinicial')} onKeyDown={aoEnter} required/>
                    </div>
                    <div className="field col-12 mt-3">
                        {permissoesTela?.alterar && <Button label="Salvar" icon="pi pi-check" severity="success" onClick={aoSalvar}/>}
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default Frase;
