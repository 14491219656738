import React           from 'react';
import ReactDOM        from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import { PublicClientApplication, EventType } from "@azure/msal-browser";

import App          from './App';
import {msalConfig} from "./services/authConfig";

import './index.css';


export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
	// Account selection logic is app dependent. Adjust as needed for different use cases.
	msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
		const account = event.payload.account;
		msalInstance.setActiveAccount(account);
	}
});

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<App pca={msalInstance} />
		</BrowserRouter>
	</React.StrictMode>
);
