import React from "react";

export const TIT_ATENCAO = 'Atenção!';
export const ICO_ATENCAO = 'pi pi-exclamation-triangle';
export const MSG_EXCLUIR = 'Confirma a exclusão do item selecionado?';
export const MSG_EXCLUIR_MULTIPLO = 'Confirma a exclusão de todos os itens selecionados?';

export const parametrosPadrao = {
	pesquisarTabela:   false,
	nomeClasse:        '',
	caminhoBreadcrumb: [],
	endpointApi:       '',
	tabelaDados:       {
		reordenar:               false,
		multiplaSelecao:         false,
		botaoAdicionar:          false,
		botaoExcluir:            false,
		botaoEditarRegistro:     false,
		botaoExcluirRegistro:    false,
		botaoAuditoriaRegistro:  false,
		botaoVisualizarRegistro: false,
		colunas:                 []
	},
	abrirFormulario:   null,
	finalizarPesquisa: null
}
