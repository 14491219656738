import React from 'react';

import {Container}               from './styles';
import Login                     from "../pages/seguranca/login";
import {UnauthenticatedTemplate} from "@azure/msal-react";

const LoginLayout = () => (
	<UnauthenticatedTemplate>
		<Container>
			<Login/>
		</Container>
	</UnauthenticatedTemplate>
);


export default LoginLayout;
