import {useMsal, useMsalAuthentication} from "@azure/msal-react";
import React, {useEffect, useState}     from "react";
import {InteractionType}                from "@azure/msal-browser";
import axios                            from "axios";
import {useNavigate}                    from "react-router-dom";
import {loginRequest}                   from "../../services/authConfig";
import {RegistrarToken}                 from "../../services/auth";
import debug                            from "../../utils/debug";

import imgMSG                          from "../../assets/ms-security.jpg";   



const Autenticacao = () => {
    let navegacao = useNavigate();
    const {result, error} = useMsalAuthentication(InteractionType.Popup, loginRequest);
    
    const autenticarSAML = async (tk) => {
        axios.request({
                          method : 'POST',
                          baseURL: process.env.REACT_APP_URLAPI,
                          url    : 'seguranca/autenticacao/saml',
                          data   : {TokenAD: tk}
                      }).then((resposta) => {
            if (resposta.status === 200) {
                RegistrarToken(resposta.data.Token, resposta.data.Refreshtoken);
                navegacao('/sistema');
            } else {
                debug('Erro de autenticação AD');
                debug(resposta);
            }
        }).catch((erro) => {
            debug(erro);
            window.location.href = '/login';
        }).finally(() => {
            //Notiflix.Loading.Remove();
        });
    }
    
    useEffect(() => {
        if (result?.accessToken) {
            autenticarSAML(result.accessToken);
        } 
    }, [result]);


    return (
        <div className="text-center">
            <img src={imgMSG} alt="Microsoft" title="Microsoft security" style={{alignItems: 'center',textAlign:"center",width: '50%'}}/>
        </div>);
}
export default Autenticacao;
