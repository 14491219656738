import React        from 'react';
import {classNames} from 'primereact/utils';

import Logo from "../assets/LogoLogin.png";

const AppTopbar = (props) => {
	const menuButtonClassName = classNames('p-link', {'menu-button-rotate': props.rotateMenuButton});
	const legendaTitulo = 'PTI';
	
	return (
		<div className="topbar clearfix">
			<div className="topbar-left" style={{display: 'flex', justifyContent: 'center'}}>
				<button className="p-link">
					<img src={Logo} alt={legendaTitulo} title={legendaTitulo} rel={"noreferrer"} style={{width: '50%'}}/>
				</button>
			</div>
			
			<div className="topbar-right">
				<button className={menuButtonClassName} id="menu-button" onClick={props.onMenuButtonClick}>
					<i className="pi pi-angle-left"></i>
				</button>
			</div>
		</div>
	);
};

export default AppTopbar;
